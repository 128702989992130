.Button {
  padding: 0.5rem 1.5rem;
  box-sizing: border-box;
  border-radius: 20px;
  border: 1px solid transparent;
  transition: all 250ms linear;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Button_theme-white {
  background-color: #fff;
  border-color: #fff;
  color: #3e3a39;
}
.Button_theme-white:focus {
  border-color: #ef7f1a !important;
}
.Button_theme-white:hover {
  border-color: #ef7f1a !important;
  background-color: #eae9e9;
}
.Button_theme-white:active {
  background: #eae9e9;
}
.Button_theme-white.Button_disabled {
  background: #eae9e9;
  border-color: #eae9e9;
  cursor: default;
}
.Button_theme-primary {
  background-color: #d4b390;
  border-color: transparent;
  color: #fff;
}
.Button_theme-primary:focus {
  background-color: #ae937c;
}
.Button_theme-primary:hover {
  background-color: #ae937c;
}
.Button_theme-primary:active {
  background-color: #ae937c;
}
.Button_theme-primary.Button_disabled {
  background: #c6b3a3;
  cursor: default;
}
.Button_transparent {
  background-color: transparent;
}
.Button_transparent.Button_theme-white {
  color: #fff;
}
.Button_transparent.Button_theme-white:focus,
.Button_transparent.Button_theme-white:hover {
  background-color: transparent !important;
}
.Button_block {
  width: 100%;
}
.Button_bordered.Button_theme-white {
  border-color: #ccc;
}
